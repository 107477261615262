import { addPropertyControls, ControlType } from "framer"

export default function LessonCompleteInterface(props) {
    return <div style={styles}></div>
}

addPropertyControls(LessonCompleteInterface, {
    slug: {
        title: "Slug",
        type: ControlType.String,
    },
    link: {
        title: "Link",
        type: ControlType.Link,
    },
})

const styles = {}
